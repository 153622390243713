<template>
    <el-dialog
            :title="title"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="140px" @submit.native.prevent>
            <el-form-item label="元数据表：" prop="metadataTable">
                <el-select v-model="inputForm.metadataTable" @change="metadataTableChange" placeholder="请选择元数据表" style="width: 100%">
                    <el-option
                            v-for="item in  $dictUtils.getDictList('table_type')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="著录项字段名：" prop="fieldEnname">
                <el-select v-model="inputForm.fieldEnname" @change="commentChange" placeholder="请选择著录项字段名" style="width: 100%">
                    <el-option
                            v-for="item in fieldNameOptions"
                            :key="item.field"
                            :label="item.comment"
                            :value="item.field">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="著录项名：" prop="recordName">
                <el-input v-model.trim="inputForm.recordName" placeholder="请输入著录项名" maxlength="50"></el-input>
            </el-form-item>
<!--            <el-form-item label="著录项编号：" prop="recordCode">-->
<!--                <el-input v-model="inputForm.recordCode" placeholder="请输入著录项编号"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="著录项类型：" prop="detailsShowType">
                <el-select v-model="inputForm.detailsShowType" placeholder="请选择著录项类型" style="width: 100%">
                    <el-option
                            v-for="item in $dictUtils.getDictList('Item_description_type')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="著录项排序：" prop="sortnumber">
                <el-input-number v-model="inputForm.sortnumber" :min="1"></el-input-number>
            </el-form-item>
            <el-form-item label="是否发布：" prop="publishState">
                <el-switch v-model="inputForm.publishState" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>
            <el-form-item label="是否显示：" prop="onShow">
                <el-switch v-model="inputForm.onShow" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>
            <el-form-item label="是否可编辑：" prop="beEdit">
                <el-switch v-model="inputForm.beEdit" :active-value="0" :inactive-value="1" @change="switchChange"></el-switch>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                loading: false,
                title: '',
                method: '',
                inputForm: {
                    id: '',
                    fieldEnname: '',
                    recordName: '',
                    recordCode: '',
                    sortnumber: 1,
                    publishState: 0,
                    onDetails:1,
                    flag:"",
                    metadataTable:"",
                    onShow:0,
                    beEdit:1,
                    detailsShowType:""
                },
                fieldNameOptions: [],
                dataRule: {
                    metadataTable: [
                        {required: true, message: '元数据表不能为空', trigger: 'change'}
                    ],
                    recordName: [
                        {required: true, message: '著录项名不能为空', trigger: 'blur'}
                    ],
                    fieldEnname: [
                        {required: true, message: '著录项字段名不能为空', trigger: 'change'}
                    ],
                    detailsShowType: [
                        {required: true, message: '著录项类型不能为空', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            switchChange(val){
                if(val==0){
                    this.$confirm(`此项设置涉及敏感操作，敬请慎重设置，必要时，敬请联系系统管理员。`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {

                    }).catch(() => {
                        this.inputForm.beEdit = 1
                    });
                }
            },
            init (method, row) {
                this.method = method
                this.inputForm.id = ""
                if (method === 'add') {
                    this.title = `新建著录项`
                    this.inputForm.flag = row
                } else if (method === 'edit') {
                    this.title = '编辑著录项'
                }
                this.visible = true
                console.log(row);
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method === 'edit') { // 修改
                        this.inputForm = this.recover(this.inputForm, row)
                        console.log(this.inputForm);
                        // this.metadataTableChange(this.inputForm.metadataTable);
                        this.$axios(this.api.zlzs.tableDate, {flag:this.inputForm.metadataTable}, 'get').then(data => {
                            if (data && data.status) {
                                this.fieldNameOptions = data.data
                            }
                        })
                    }
                })
            },
            commentChange(val){
                let that = this;
                this.fieldNameOptions.forEach(function (v) {
                    if(val == v.field){
                        that.inputForm.recordName = v.comment
                    }
                })

            },
            metadataTableChange(val){
                this.inputForm.fieldEnname = ""
                this.inputForm.recordName = ""
                this.$axios(this.api.zlzs.tableDate, {flag:val}, 'get').then(data => {
                    if (data && data.status) {
                        this.fieldNameOptions = data.data
                    }
                })
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if(this.title==`新建著录项`){
                            this.$axios(this.api.zlzs.originalDescriptionSave, this.inputForm, 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success(data.msg)
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }else{
                                    this.$message.error("著录项已存在")
                                }
                            })
                        }else{
                            this.$axios(this.api.zlzs.originalDescriptionUpdateById, this.inputForm, 'put').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success(data.msg)
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }else{
                                    this.$message.error("著录项已存在")
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>
